import { Icon } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { create } from "zustand";

export type ToastType = "info" | "success" | "warning" | "error";

export interface ToastConfig {
  type?: ToastType;
  message?: string;
  icon?: Icon;
  timeout?: number; // in seconds
  isStatic?: boolean;
  isDismissable?: boolean;
  children?: ReactNode | ((onDismiss: () => void) => ReactNode);
}

export interface ToastState extends ToastConfig {
  id: number;
}

export interface RecordsState {
  lastId: number;
  toasts: ToastState[];
  addToast: (config: ToastConfig) => void;
  removeToast: (id: number) => void;
}

export const useToasts = create<RecordsState>()((set, get) => ({
  lastId: 0,
  toasts: [],
  addToast: (config) =>
    set({
      toasts: [...get().toasts, { ...config, id: get().lastId + 1 }],
      lastId: get().lastId + 1,
    }),
  removeToast: (idToRemove) =>
    set({ toasts: get().toasts.filter(({ id }) => id !== idToRemove) }),
}));

export const toast: Record<ToastType, (config: ToastConfig) => void> = {
  info: (config) => useToasts.getState().addToast({ type: "info", ...config }),
  warning: (config) =>
    useToasts.getState().addToast({ type: "warning", ...config }),
  success: (config) =>
    useToasts.getState().addToast({ type: "success", ...config }),
  error: (config) =>
    useToasts.getState().addToast({ type: "error", ...config }),
};
