export enum ChargingProcessStatus {
  PAYMENT_INTENT_INVALID = "PAYMENT_INTENT_INVALID",
  PAYMENT_INTENT_VALID = "PAYMENT_INTENT_VALID",
  START_SENT_TO_STATION = "START_SENT_TO_STATION",
  CHARGING_PROCESS_STARTED = "CHARGING_PROCESS_STARTED",
  START_EXPIRED = "START_EXPIRED",
  CHARGING_PROCESS_FINISHED = "CHARGING_PROCESS_FINISHED",
}

export type ChargingProcessStatusResponse =
  | {
      chargingProcessStatus: ChargingProcessStatus.CHARGING_PROCESS_STARTED;
      paymentIntentStatus: "requires_capture";
      chargingProcessId: number;
    }
  | {
      chargingProcessStatus: ChargingProcessStatus.PAYMENT_INTENT_INVALID;
      paymentIntentStatus: string;
    }
  | {
      chargingProcessStatus: ChargingProcessStatus.PAYMENT_INTENT_VALID;
    }
  | {
      chargingProcessStatus: ChargingProcessStatus.START_SENT_TO_STATION;
    }
  | {
      chargingProcessStatus: ChargingProcessStatus.START_EXPIRED;
    }
  | {
      chargingProcessStatus: ChargingProcessStatus.CHARGING_PROCESS_FINISHED;
      paymentIntentStatus: "succeeded";
    };
