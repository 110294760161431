import { create } from "zustand";

export interface AuthState {
  setAuthState: (state: Partial<AuthState>) => void;
  token: string | null;
  userIdentification: string | null;
  userName: string | null;
}

export const useAuth = create<AuthState>()((set, get) => ({
  setAuthState: set,
  token: null,
  userIdentification: null,
  userName: null,
}));
