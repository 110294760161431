import { Info } from "@phosphor-icons/react";
import clsx from "clsx";
import { HTMLProps, useCallback, useEffect, useMemo, useState } from "react";
import { ToastState, ToastType, useToasts } from "./state";
import Message, { MessageColor } from "./Message";

const DEFAULT_TIMEOUT = 5; //seconds

type Props = ToastState &
  Omit<HTMLProps<HTMLDivElement>, "id" | "color" | "children" | "size">;

export default function Toast({
  id,
  type,
  timeout = DEFAULT_TIMEOUT,
  isStatic,
  isDismissable = true,
  icon = Info,
  className,
  children,
  ...props
}: Props) {
  // 0 - entering, 1 - visible, 2 - existing
  const [visibleState, setVisibleState] = useState<0 | 1 | 2>(0);
  const removeToast = useToasts((s) => s.removeToast);

  const color = useMemo(() => getMessageColorByToastType(type), [type]);

  const onClose = useCallback(() => {
    setVisibleState(2);
    setTimeout(() => {
      removeToast(id);
    }, 300);
  }, [removeToast, id]);

  useEffect(() => {
    if (!isStatic) {
      setTimeout(() => {
        onClose();
      }, timeout * 1000);
    }
  }, [onClose, timeout, isStatic]);

  useEffect(() => {
    setVisibleState(1);
  }, []);

  return (
    <Message
      border
      color={color}
      icon={icon}
      className={clsx(
        "transform transition ease-in-out duration-300 z-50 pointer-events-auto shadow-lg md:max-w-lg",
        {
          "active:scale-95 cursor-pointer": isDismissable,
          "opacity-0 -translate-y-full": visibleState === 0,
          "opacity-100 translate-y-0": visibleState === 1,
          "opacity-0 translate-x-full": visibleState === 2,
        },
        className
      )}
      onClick={() => isDismissable && onClose()}
      {...props}
    >
      {typeof children === "function" ? children(onClose) : children}
    </Message>
  );
}

export function getMessageColorByToastType(
  type: ToastType = "info"
): MessageColor {
  switch (type) {
    case "info":
      return "blue";
    case "error":
      return "red";
    case "warning":
      return "yellow";
    case "success":
      return "green";
    default:
      return "blue";
  }
}
