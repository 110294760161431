import { NextApiResponse } from "next";

export function parseAPIError(
  error: any,
  res: NextApiResponse,
  generalError?: string
) {
  console.dir(error);

  if (typeof error === "object" && error?.response) {
    res.status(error.response.status).json(error.response?.data);
  } else {
    res.status(400).json({
      message: generalError,
    });
  }
}

export function parseClientError(error: any, errorIdentifier?: string) {
  console.dir(error);

  if (typeof error === "object" && error) {
    const identifier = errorIdentifier || error?.config?.url || "NO_IDENTIFIER";
    const status = error.response?.status || "UNKNOWN";
    const data = JSON.stringify(error?.response?.data || {});

    console.error(`${identifier} | Status - ${status} | Data - ${data}`);
  }
}
