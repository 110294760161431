export enum COOKIE {
  TOKEN = "token",
  USER_NAME = "userName",
  USER_IDENTIFICATION = "userIdentification",
  HAS_CONSENT = "hasConsent",
  CONSENT_CHOSEN = "consentChosen",
  CHARGING_PROCESS_ID = "chargingProcessId",
  HAS_FINISHED_CHARGING = "hasFinishedCharging",
  PAYMENT_INTENT_ID = "paymentIntentId",
  STRIPE_CLIENT_SECRET = "stripeClientSecret",
}
