import { i18n } from "next-i18next";
import { create } from "zustand";
import { datadogRum } from "@datadog/browser-rum";
import { DD_GLOBAL_PROPERTY } from "@utils/datadog";
import { api } from "@utils/api";
import { Connector } from "@utils/types/Connector";
import { Station } from "@utils/types/Station";
import { ConnectorStatus } from "@utils/types/ConnectorStatus.enum";
import { toast } from "@components/toasts/state";
import { parseClientError } from "@utils/errors";

export interface StationState {
  setStationState: (state: Partial<StationState>) => void;
  station: Station | null;
  connector: Connector | null;
  isStationLoading: boolean;
  isScanning: boolean;
  setConnectorStatus: (status: ConnectorStatus | null) => void;
  getStationData: () => Promise<void>;
  setSerialAndEvseId: (serialAndConnector: {
    serialNumber: string;
    evseId: string;
  }) => void;
}

export const useStation = create<StationState>()((set, get) => ({
  setStationState: set,
  station: null,
  connector: null,
  isStationLoading: true,
  isScanning: false,
  setConnectorStatus: (status) => {
    const connector = get().connector;

    if (status && connector && status !== connector?.status) {
      datadogRum.setGlobalContextProperty(
        DD_GLOBAL_PROPERTY.CONNECTOR_STATUS,
        status
      );

      set({ connector: { ...connector, status } });
    }
  },
  setSerialAndEvseId: ({ serialNumber, evseId }) => {
    set({ station: null, connector: null, isStationLoading: true });
    const query = new URLSearchParams();
    query.set("id", serialNumber);
    if (evseId) {
      query.set("evseId", evseId);
    }
    window.location.search = query.toString();
  },
  getStationData: async () => {
    const query = new URLSearchParams(window.location.search);
    const serialNumber = query.get("id");
    const connectorId = query.get("s");
    const evseId = query.get("evseId");

    if (!serialNumber || (!connectorId && !evseId)) {
      set({ isStationLoading: false });
      return;
    } else {
      set({ isStationLoading: true });
    }

    try {
      const {
        data: { station, connector },
      } = await api.get<{
        station: Station;
        connector: Connector;
      }>("/station/getStationBySerial", {
        params: { serialNumber, connectorId, evseId },
      });

      datadogRum.setGlobalContextProperty(DD_GLOBAL_PROPERTY.STATION, station);
      datadogRum.setGlobalContextProperty(
        DD_GLOBAL_PROPERTY.CONNECTOR,
        connector
      );

      set({
        station,
        connector,
        isStationLoading: false,
      });
    } catch (error: any) {
      toast.error({ message: i18n?.t("error.stationData") });
      parseClientError(error, "GET_STATION");
    } finally {
    }
  },
}));
