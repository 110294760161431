import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export function initDatadog() {
  if (process.env.NODE_ENV === "production") {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID!,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
      site: "datadoghq.eu",
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE!,
      env: process.env.NEXT_PUBLIC_ENV,
      version: process.env.COMMIT_HASH,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE!,
      site: "datadoghq.eu",
      env: process.env.NEXT_PUBLIC_ENV,
      version: process.env.COMMIT_HASH,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ["error"],
      sessionSampleRate: 100,
    });
  }
}

export enum DD_ACTION {
  START_CHARGING = "START_CHARGING",
  START_CHARGING_TIMEOUT = "START_CHARGING_TIMEOUT",
  STOP_CHARGING = "STOP_CHARGING",
  QR_SCAN_START = "QR_SCAN_START",
  QR_SCAN_SUCCESS = "QR_SCAN_SUCCESS",
  QR_SCAN_FEEDBACK_SENT = "QR_SCAN_FEEDBACK_SENT",
  QR_SCAN_OPENED_APP = "QR_SCAN_OPENED_APP",
  QR_SCAN_ERROR_WRONG_URL = "QR_SCAN_ERROR_WRONG_URL",
  QR_SCAN_ERROR_EVSE_OPERATOR_NOT_SERVED = "QR_SCAN_ERROR_EVSE_OPERATOR_NOT_SERVED",
  QR_SCAN_ERROR_PLACE_NOT_FOUND = "QR_SCAN_ERROR_PLACE_NOT_FOUND",
  QR_SCAN_ERROR_EVSEID_INVALID = "QR_SCAN_ERROR_EVSEID_INVALID",
  QR_SCAN_ERROR_UNKNOWN = "QR_SCAN_ERROR_UNKNOWN",
  QR_SCAN_ERROR_EVSE_NOT_IN_URL = "QR_SCAN_ERROR_EVSE_NOT_IN_URL",
}

export enum DD_ERROR {
  QR_SCAN_SEND_FEEDBACK = "QR_SCAN_SEND_FEEDBACK",
  GET_PAYMENT_INTENT_STATUS = "GET_PAYMENT_INTENT_STATUS",
}

export enum DD_GLOBAL_PROPERTY {
  CHARGING_PROCESS_ID = "CHARGING_PROCESS_ID",
  PAYMENT_INTENT_ID = "PAYMENT_INTENT_ID",
  STATION = "STATION",
  CONNECTOR = "CONNECTOR",
  CONNECTOR_STATUS = "CONNECTOR_STATUS",
  HAS_SCANNED_QR_CODE = "HAS_SCANNED_QR_CODE",
}

export enum DD_USER_PROPERTY {
  USER_IDENTIFICATION = "USER_IDENTIFICATION",
}
