import "@styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Inter } from "next/font/google";
import localFont from "next/font/local";
import { appWithTranslation } from "next-i18next";
import ToastsContainer from "@components/toasts/ToastsContainer";
import StorageStateSync from "@utils/StorageStateSync";
import Auth from "@features/Auth/Auth";
import SupportInfoBar from "@components/SupportInfoBar";

export const inter = Inter({ subsets: ["latin"], variable: "--font-inter" });
export const officina = localFont({
  src: "../styles/fonts/officina.woff2",
  variable: "--font-officina",
});

function App({ Component, pageProps }: AppProps) {
  return (
    <main
      className={`h-screen w-full max-h-screen max-w-full ${officina.variable} ${inter.variable} font-sans flex flex-col`}
    >
      <Head>
        <title>LichtBlick eMobility Zahlungen</title>
        <link href="/favicon.svg" rel="icon" type="image/svg+xml" />
      </Head>

      <ToastsContainer />

      <SupportInfoBar />

      <StorageStateSync>
        <Auth>
          <Component {...pageProps} />
        </Auth>
      </StorageStateSync>
    </main>
  );
}
export default appWithTranslation(App);
