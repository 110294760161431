import { ReactNode, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { COOKIE } from "./types/cookies";
import { useAuth } from "@features/Auth/state";
import { useChargingProcess } from "@features/ChargingProcess/state";
import Spinner from "@components/Spinner";

function useCookieSync(
  cookieName: COOKIE,
  valueAndSetter: { value: any; setter?: (value: any) => void },
  maxAge?: number // in seconds
) {
  const [cookies, setCookie, deleteCookie] = useCookies([cookieName]);
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    valueAndSetter.setter?.({ [cookieName]: cookies[cookieName] });
    setHasInitialized(true);
  }, [cookies[cookieName], valueAndSetter.setter]);

  useEffect(() => {
    if (hasInitialized) {
      if (valueAndSetter.value) {
        if (process.env.NODE_ENV === "development") {
          console.log("SET_COOKIE", { [cookieName]: valueAndSetter.value });
        }
        setCookie(cookieName, valueAndSetter.value, { maxAge });
      } else {
        deleteCookie(cookieName);
      }
    }
  }, [cookieName, valueAndSetter.value, hasInitialized, maxAge]);
}

export default function StorageStateSync({
  children,
}: {
  children: ReactNode;
}) {
  const [isSynced, setIsSynced] = useState(false);

  const setAuthState = useAuth((s) => s.setAuthState);
  const token = useAuth((s) => s.token);
  const userIdentification = useAuth((s) => s.userIdentification);
  const userName = useAuth((s) => s.userName);

  const setChargingProcessState = useChargingProcess(
    (s) => s.setChargingProcessState
  );
  const chargingProcessId = useChargingProcess((s) => s.chargingProcessId);
  const paymentIntentId = useChargingProcess((s) => s.paymentIntentId);
  const stripeClientSecret = useChargingProcess((s) => s.stripeClientSecret);

  useCookieSync(COOKIE.TOKEN, { value: token, setter: setAuthState });
  useCookieSync(COOKIE.USER_NAME, { value: userName, setter: setAuthState });
  useCookieSync(COOKIE.USER_IDENTIFICATION, {
    value: userIdentification,
    setter: setAuthState,
  });
  useCookieSync(
    COOKIE.CHARGING_PROCESS_ID,
    { value: chargingProcessId, setter: setChargingProcessState },
    24 * 60 * 60
  );
  useCookieSync(
    COOKIE.PAYMENT_INTENT_ID,
    { value: paymentIntentId, setter: setChargingProcessState },
    24 * 60 * 60
  );
  useCookieSync(
    COOKIE.STRIPE_CLIENT_SECRET,
    { value: stripeClientSecret, setter: setChargingProcessState },
    24 * 60 * 60
  );

  useEffect(() => {
    setIsSynced(true);
  }, []);

  if (!isSynced) {
    return (
      <div className="flex flex-col items-center pt-8">
        <Spinner />
      </div>
    );
  }
  return children;
}
